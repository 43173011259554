@use "../variables";
@use "../mixins";

/* Category list view. */

.category-description {
    background-color: variables.$neutral-lighter-25;

    &__container {
        margin: 0 112px;
        padding-top:15px;
    }

    &__wrapper {
        display: flex;
    }

    &--left {
        width: 292px;

        .breadcrumbs {
            margin-top: 26px;
            white-space: nowrap;
        }
    }

    &--right {
        margin-left: 30px;
        flex-grow: 1;
        padding: 20px 0;
    }
    .read__more {
        margin-top: 6px;
    }

    .read__less {
        display: none;
    }

    &--expanded {
        .category-description__text {
            display: block;
            height: auto;
        }

        .read__more {
            display: none;
        }

        .read__less {
            display: block;
        }
    }

    &__text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: variables.$responsive-break;
        &.long {
            -webkit-line-clamp: 20;
            max-width: variables.$mobile-break;
            font-size: 16px;
        }
        
        color: variables.$neutral-dark;

        > * {
            display: inline;
        }
        // We need to have elements as inline to make iphone line clamp work. 
        // So we add indention after headings and p-s.
        p::after, h1::after, h2::after, h3::after, h4::after,h5::after,h6::after  {
            content: " \A";
            white-space: pre;
        }
    }
    
    .dots {
        padding: 10px 10px 10px 30px;
        
        > li {
            list-style: disc;
            margin-top: 8px;
        }
        
        ::marker {
            color: variables.$black;
            font-weight: variables.$font-weight-bold;
        }
    }
}

.category-description.footer-description {
    h2 {
        font-size: variables.$font-size-xl;
    }
}

.faq-description{
    .category-description__text{
        width: 100%;
        display: block;
        
        .accordion__item{
            background-color: variables.$neutral-lighter-21;
        }
    }
}

.responsive-description {
    display: none;
    background-color: variables.$neutral-lighter-25;
    .category-description--right{
        padding: 15px 20px 20px;
        margin: unset;
    }
}

.category {
    margin: 0 112px;

    .responsive-sort,
    .scroll-category,
    .sort-up-down,
    .sort-and-filter .responsive-filter-button {
        display: none;
    }

    .tabs-menu__list {
        display: flex;
        justify-content: flex-end;
    }

    .dropdown {

        &__list {
            z-index: variables.$zindex-header-overlay;
        }
    }

    .product-card__list {
        &--vertical {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        
        &--banner {
            grid-column: 1/4;
            grid-row-start: 2;
            border-style: none solid solid none;
            border-color: variables.$neutral-lighter-100;
            border-width: 1px;
        }
        
        .top {        
            grid-row-start: 1;
            + .product-card__list--banner{
                grid-row-start: 3;        
            }
        }
    }

    .breadcrumbs {
        max-width: 1216px;
        margin: 0 auto;
        padding-top: 16px;
    }

    &__header {
        width: 300px;
        font-size: 30px;
        color: variables.$primary;
        font-weight: variables.$font-weight-normal;
        margin: 14px 0;
    }

    &__wrapper {
        display: flex;
        margin-top: 24px;

        &--right {
            margin-left: 30px;
        }

        .sidebar {
            width: 300px;
        }

        .brand-logo {
            width: 150px;
        }
    }

    .product-card__list {
        margin-top: 26px;
    }
    
        .loading-button {
            display: flex;
            width: 50%;
            margin: 16px auto 60px auto;
        }

    .recently-viewed {
        margin-bottom: 60px;
    }

    .sort-menu {

        &__title {
            margin-top: 0;
        }
    }

    .category__wrapper {
        .category__wrapper--right {
            .footer-description,
            .faq-description {
                border-radius: 10px;
                align-items: center;
                display: flex;
                flex-direction: column;
                background-color: variables.$neutral-lighter-21;
                padding: 32px 42px;
            }
            
            .faq-description{
                margin-top: 20px;
            }
        }
    }
}

@media only screen and (max-width: 1919px) {
    .category-description__container,
    .category {
        margin: 0 auto;
        padding: 15px 40px 0 40px;
    }
    
    .image-slider__content .image-slider__item .image-slider__img.listcarousel {
        height: 300px;
    }
}


@media only screen and (min-width: 1920px) {
    .category {
        .product-card__list {
            &--vertical {
                grid-template-columns: repeat(5, 1fr);
            }
            &--banner {
                grid-column: 1/6;
                border-style: none solid solid none;
                border-color: variables.$neutral-lighter-100;
                border-width: 1px;
            }
        }
    }
}

.campaign-heading {
    color: variables.$primary;
}

@media(min-width: variables.$responsive-break) {
    .category {
        .sort-and-filter {
            margin-top: 24px;
        }

        .product-card__list--vertical .product-card.vertical {
            width: auto;
        }
    }
    
    .responsive-sticky-padding{
        display: none;
    }
}


@media (max-width: variables.$responsive-break - 1) {

    .category-description--header {
        display: none;
    }
    
    .responsive-description {
        display: block;
    }

    .header-service .tabs {
        display: none;
    }

    #category-filter-panel {
        overflow: auto;

        .category__wrapper {

            &--left {
                padding-top: 50px;
                padding-left: 16px;

                .category__header {
                    width: auto;
                    font-size: variables.$font-size-lg;
                    font-weight: variables.$font-weight-normal;
                }

                .sort-menu__title {
                    border-bottom: 2px solid variables.$neutral-lighter-50;
                }
            }
        }

        &-overlay {
            &.panel__overlay--active {
                transform: none;
                left: 0;
            }
        }
    }

    .category {
        padding: 0;

        .breadcrumbs {
            display: none;
        }

        .loading-button {
            width: 80%;
        }

        .category__wrapper {

            // Category jumplists are rendered for desktop by default. Mobile jumplists are converted from desktop.
            // GroupLandingPage structure is different than deeper groups. 
            // - GroupLandingPage links are simply inside unordered list.
            // - Deeper groups links are radio buttons inside filters section.
            .sort-menu__list.group-landingpage {
                display: flex;
                justify-content: space-between;
                height: 50px;

                .sort-menu__item {
                    border-left: 1px solid variables.$gray-50;
                    margin: 0 10px 0 10px;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;

                    &:first-of-type {
                        margin-left: auto;
                        padding-left: 16px;
                        border: 0;
                    }

                    li {
                        margin: 0 10px 0 10px;
                    }

                    i {
                        display: none;
                    }

                    a {
                        padding: 6px 24px 4px 0;
                        color: variables.$neutral-darker;
                        font-weight: 400;
                    }
                }
            }

            .responsive-sort {
                display: block;
                grid-row: 1;
                overflow: auto;
                overflow-y: hidden;
                margin: 0 auto;
                max-width: 100%;
                height: 50px;
                z-index: variables.$zindex-sticky;
                background-color: variables.$white;
                width: 100%;
                top: -100%;
                transition: all 0.3s ease-in-out;
                
                &-sticky {
                    top: 0;
                    position: fixed;
                }

                @include mixins.scrollbars;

                .sort-menu {
                    width: 100%;

                    h2 {
                        display: none;
                    }

                    &__list {
                        margin: 0;

                        .sort-menu__item {
                            button {
                                display: none;
                            }
                        }

                        //show category elements
                        .sort-menu__item {
                            margin: 0;

                            .sort-menu__sub {
                                display: flex;
                                justify-content: space-between;
                                margin: 0;
                                padding: 0;
                                height: 50px;
                            }

                            &.category-jumplist {
                                display: block;

                                li {
                                    display: block;

                                    &:first-of-type {
                                        border-left: 0;
                                    }

                                    border-left: 1px solid variables.$neutral-lighter-50;

                                    div {
                                        margin: auto;
                                        padding: 0;
                                        height: 100%;
                                    }

                                    .custom-radio {
                                        white-space: nowrap;

                                        &.checked {
                                            background-color: variables.$primary-lighter-10;
                                        }

                                        &__label {
                                            padding: 0 24px;

                                            &::before {
                                                content: none;
                                            }

                                            &::after {
                                                content: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .category__header {
                display: block;
                padding-left: 20px;
            }

            display: grid;
            grid-template-rows: auto;
            margin: 0;

            .scroll-category.d-flex {
                position: absolute;
                width: 100%;
                display: flex;

                .scroll-left, .scroll-right {
                    position: absolute;
                    height: 50px;
                    width: 32px;
                    padding: 0;
                    background-color: variables.$white;
                    color: variables.$secondary;
                    border: 0;
                }

                .scroll-left {
                    left: 0;
                    border-radius: 0 12px 12px 0;
                    filter: drop-shadow(4px 0px 4px #CCC);
                }

                .scroll-right {
                    right: 0;
                    border-radius: 12px 0 0 12px;
                    filter: drop-shadow(-4px 0px 4px #CCC);
                }
            }

            .category__wrapper--left {
                display: none;
            }

            .category__wrapper--right {
                display: flex;
                flex-direction: column;
                grid-row: 2;
                overflow: auto;
                margin: 0 2px;

                .noproducts, .campaign-heading {
                    display: none;
                }

                .tags {
                    margin: 8px;

                    &__list {
                        margin: 8px 0;
                    }

                    .tags__wrapper {
                        margin-top: 0;

                        &--primary {
                            display: block;
                        }
                    }
                }

                .sort-and-filter {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding: 0 0 8px;
                    z-index: variables.$zindex-sticky;
                    background: variables.$white;
                    width: 100%;
                    top: -100%;
                    transition: all 0.3s ease-in-out;
                    max-height: 85px;

                    &-sticky {
                        top: 50px;
                        position: fixed;
                    }

                    .product-list-sort, .responsive-filter-button {
                        width: 250px;
                        margin: 8px 8px 0;
                    }

                    #list-sort-dropdown,
                    .product-list-sort .dropdown__list,
                    .responsive-filter-button button {
                        background: linear-gradient(180deg, variables.$white 0%, #F3F3F3 100%);
                        border: 1px solid #E6E6E6;
                        box-sizing: border-box;
                        border-radius: 7px;
                        
                        .dropdown__selected:hover {
                            background: none;
                        }
                    }

                    .product-list-sort {
                        span {
                            display: none;
                        }

                        #list-sort-dropdown, .dropdown__list {
                            .dropdown__selected {
                                text-align: center;
                                padding: 0;
                                height: 100%;
                                white-space: normal;

                                .sort-up-down {
                                    display: none;
                                }
                                
                                .sort-selected-item {
                                    display: block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    padding: 12px 24px;
                                }
                            }
                        }
                        
                        .dropdown__list-item {
                            text-wrap: auto;
                        }
                    }

                    .responsive-filter-button {
                        display: block;

                        button {
                            width: 100%;
                            line-height: initial;

                            span {
                                font-weight: variables.$font-weight-normal;
                                font-size: variables.$font-size-sm;
                                color: variables.$neutral-darker;
                            }
                            
                            .icon {
                                display: none;
                            }
                        }
                    }
                }

                .product-card__list {
                    display: grid;
                    flex-wrap: wrap;
                    grid-template-columns: 1fr 1fr;
                    margin: auto;
                    grid-row-gap: 4px;
                    grid-column-gap: 6px;
                    border: 0;
                    overflow: hidden;
                    justify-content: center;

                    &--banner {
                        grid-column: 1/3;
                        grid-row-start: 1;
                    }
                }
                
                .footer-description,.faq-description {
                    margin: 0 2px 42px 2px;
                    padding: 20px 0;
                }
            }
        }
    }
}

@media (max-width: variables.$mobile-break) {
    .category .category__wrapper .category__wrapper--right {
        .product-card__list {
            grid-template-columns: 1fr;
            
            &--banner {
                grid-column: 1/2;
                grid-row-start: 1;
            }            
        }

        .sort-and-filter{
            .product-list-sort{
                width: 47%;
                margin-right: 0;
                #list-sort-dropdown{
                    border-radius: 7px 0 0 7px;
                    border: 1px solid #E6E6E6;
                    border-right: none;
                    height: 44px;
                    align-content: center;

                    .dropdown__selected {
                        padding: 0;
                        border-radius: 7px 0 0 7px;
                    }
                    
                    .sort-up-down {
                        display: none;
                    }
                    
                    > * {
                        align-content: center;
                    }
                }
            }
            .responsive-filter-button{
                width: 47%;
                margin-left: 0;
                .category-filter-panel-button{
                    border-radius: 0 7px 7px 0;
                    height: 44px;
                }
            }
        }
    }
}

@if variables.$site-nova {
    .category {
        background-color: variables.$white;

        .category__wrapper {
            .category__wrapper--left {
                border-right: 1px solid variables.$neutral-lighter-50;

            }
        }
    }
}