@use '../variables';

.suggestions {
  margin: 32px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  line-height: 2;
  color: variables.$primary;

  h3 {
    color: variables.$primary;
    font-size: variables.$font-size-lg;
  }

  > * {
    width: 500px;
    margin: 32px;
    font-size: variables.$font-size-default;
  }
}

.popular-products {
  h3 {
    font-size: variables.$font-size-lg;
    color: variables.$primary;
  }

  ul {
    display: flex;
    margin: 16px 0;

    li {
      border: 1px solid variables.$neutral-lighter-100;
      flex-grow: 1;
      flex-basis: 0;
      padding: 24px;

      img {
        display: block;
        margin: auto;
      }
    }
  }
}
